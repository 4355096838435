
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Dandelon;
  src: url(/assets/fonts/dandeleon/DandeleonVintageDemo.otf) format("opentype");
}

@font-face {
  font-family: Monteserrat Semibold;
  src: url(/assets/fonts/Montserrat/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: Monteserrat Regular;
  src: url(/assets/fonts/Montserrat/Montserrat-Regular.ttf);
}


$color-red: #8b0000;

$color-yellow: #F0DE66;
$color-grey: #E3E3E3;
$color-dark-grey: #2D2D2D;
$color-blue: #6BCDCD;

$text-norm: 1rem;
$text-h1: 1.5rem;
$text-h2: 1.2rem;

$padding-small: 6px;
$padding: 12px;
$padding-large: 24px;

html {
  scroll-behavior: smooth;
}

.bg-white {
  background-color: white;
}

.bg-grey {
  background-color: $color-grey;
}

.bg-yellow {
  background-color: $color-yellow;
}

.bg-red {
  background-color: $color-red;
  color: white;
}

.bg-dark-grey {
  background-color: $color-dark-grey;
  color: white;
}

.semibold-text {
  font-family: 'Monteserrat Semibold';
}

.regular-text {
  font-family: 'Monteserrat Regular';
}

.dandelon-text {
  font-family: 'Dandelon';
  position: relative;
}

.btn-danger {
  background-color: $color-red !important;
  font-family: 'Monteserrat Semibold';
  font-size: 18px;
  padding: 5px 10px;
  width: fit-content;
  h4 {
    margin-bottom: 0;
  }
}

.red-text {
  color: $color-red !important;
}

.all-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

h4 {
  font-size: 20px;
  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
}

h2 {
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

p {
  font-family: 'Monteserrat Regular';
  font-size: 16px;
  margin: 0;
}

.container-fluid {
  padding: 0;
}

.row {
  margin: 0;
}

//adding-mini
.bg-transparent
{
  background: rgba(76, 175, 80, 0.3) 
}
.cl-white
{
  color: white;
}
@media only screen and (min-width: 1200px) {
    app-homepage-destinations-carousel,app-tours-carousel,app-art-craft-carousel,app-village-carousel,app-district-carousel{
    .carousel-inner
    {
      width: auto!important;
      margin: 0px 50px!important;
      border-radius: 10px!important;
    }
    }
  }

  .navbar-toggler-icon {
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/b/b2/Hamburger_icon.svg')!important;
  }

  app-homepage-journey-carousel{
    .slick-next:before {
      color: #8B2114 !important;
      font-size: 30px;
}
.slick-prev:before {
  color: #8B2114 !important;
  font-size: 30px;


}
  }


  .instafeed a img {
    position: relative;
    width: 100%;
    padding: 15px;
    object-fit: cover;

}


@media (min-width: 992px){
  .instafeed a img  {
    flex: 0 0 33.33333333%!important;
    max-width: 33.33333333%!important;
    height: 20rem!important;
}
}

@media (min-width: 767px) and (max-width: 992px){
  .instafeed a img  {
    flex: 0 0 33.33333333%!important;
    max-width: 33.33333333%!important;
    height: 14rem!important;
}
}
@media (min-width: 576px) and (max-width: 767px){
  .instafeed a img  {
    flex: 0 0 33.33333333%!important;
    max-width: 33.33333333%!important;
    height: 10rem!important;
}
}
@media (max-width: 576px) {
  .instafeed a img  {
    flex: 0 0 33.33333333%!important;
    max-width: 33.33333333%!important;
    height: 10rem!important;
    padding: 2px;
    }
    .pad{
      padding: 0;
    }
    app-instagram{
      .container{
        padding: 0;
      }
      .pad{
        padding: 0;
      }
    }
}

@media (max-width: 499px) {
  .instafeed a img  {
    flex: 0 0 33.33333333%!important;
    max-width: 33.33333333%!important;
    height: 8rem!important;
    padding: 2px;
    }
  }


.instafeed a img:hover{
  transition: 300ms;
  opacity: 0.8;
}
app-homepage-map {
  .p-1{
    padding: 1rem;
  }
  .btn {
    box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
    padding: 0.4rem 1.2rem;
    font-size: .81rem;
    transition: all .2s ease-in-out;
    margin: 0.375rem;
    border: 0;
    border-radius: 0.125rem;
    cursor: pointer;
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    color: inherit;
}
.card{
  border: none;
}
// .mapboxgl-popup-content{
//   background-color: #F0DE66 !important;
// }
}

.map-btn-col{
  background-color: #8b0000 !important;
  color: #fff !important;
}
app-art-craft-artist-profile{
  .carousel-control-prev{
    @media screen and (max-width: 576px) {
      margin-left: -14px;
    }
    
  }
  .carousel-control-next{
    @media screen and (max-width: 576px) {
      margin-right: -14px;
    }
    
  }
}
.playlist-carousel-wrapper .slick-prev:before,
.playlist-carousel-wrapper .slick-next:before {
  color: #8B2114 !important;
  font-size: 30px;
}